<template>
  <div id="app">
    <Loader :isLoading="!ready"></Loader>
    <Push class="d-sm-none" :right="true" :closeOnNavigation="true" width="205">
      <div class="d-flex flex-column align-items-center menu-push">
        <a id="home" href="https://forms.gle/gptDoM5YSxoFieFL6">
          <span>LISTING</span>
        </a>
        <a href="#footer">
          <span>FOLLOW</span>
        </a>
        <a href="/api">
          <span>API</span>
        </a>
        <a href="#how-it-works">
          <span class="menu-btn">FAQ</span>
        </a>
      </div>
    </Push>
    <main id="page-wrap">
      <Menu></Menu>
      <Header title="Simple Investment Choice" text="Don't know what to invest in? We have a solution!"></Header>
      <div v-if="ready">
        <Sort @sortBest="sortBest(null, main)" @sortWorst="sortWorst(null, main)"></Sort>
        <div class="container-fluid d-flex justify-content-center">
          <div class="row align-items-start">
            <BobbleTemp v-for="info in main.bobles" :key="info.symbol" :boble="info" @modal="setModal"
              class="col-4 my-2 my-md-4">
            </BobbleTemp>
          </div>
        </div>
        <div v-if="!main.show" class="container text-center">
          <hr />
          <button class="mt-2 mb-4 btn btn-outline-light" @click="showItems(main)">SHOW MORE</button>
        </div>
        <ProgressTitle title="Stats For Today"
          text="By daily examining this statistics you would always be aware of current market trends"></ProgressTitle>
        <Sort @sortBest="sortBest(null, progress)" @sortWorst="sortWorst(null, progress)"></Sort>
        <ProgressBar class="progress my-4" v-for="info in progress.bobles" :key="info.symbol"
          :width="info.curPercent > 0 ? info.curPercent : '50'" :symbol="info.symbol"></ProgressBar>
        <div v-if="!progress.show" class="container text-center">
          <hr />
          <button class="mt-2 mb-4 btn btn-outline-light" @click="showItems(progress)">SHOW MORE</button>
        </div>
      </div>
      <HowItWorks id="how-it-works" class="mt-4"></HowItWorks>
      <Footer id="footer"></Footer>
      <SweetModal overlay-theme="dark" modal-theme="dark" ref="modal">
        <Modal :name="test.name" :symbol="test.symbol" :description="test.description" :price="test.price" :mc="test.mc"
          :volume="test.volume" :priceChange="test.priceChange"></Modal>
      </SweetModal>
    </main>
  </div>
</template>

<script>
  import Menu from './components/Menu.vue'
  import Header from './components/Header.vue'
  import Sort from './components/Sort.vue'
  import HowItWorks from './components/HowItWorks.vue'
  import BobbleTemp from './components/BobbleTemp.vue'
  import ProgressTitle from './components/ProgressTitle.vue'
  import ProgressBar from './components/ProgressBar.vue'
  import Footer from './components/Footer.vue'
  import Modal from './components/Modal.vue'
  import { Push } from 'vue-burger-menu'
  import { SweetModal } from 'sweet-modal-vue'
  import Loader from './components/Loader.vue'
  import * as io from 'socket.io-client'

  let socket = io.connect('https://boblles.com');
  // console.log('Socket: '+ socket);

  export default {
    name: 'App',
    components: {
      Menu,
      Push,
      Header,
      Sort,
      HowItWorks,
      BobbleTemp,
      ProgressTitle,
      ProgressBar,
      Footer,
      SweetModal,
      Modal,
      Loader
    },
    created: async function () {
      socket.emit('getBobles');

      socket.on('getBobles', (info) => {
        info.sort(function (a) {
          if (a.newBoble) return -1;
          else return 1;
        });
        info.forEach(element => {
          element.active = false;
          element.lastDir = element.dir;
          element.voted = element.dir != null;
        });
        this.all.bobles = info;

        this.main.bobles = this.progress.bobles = info.slice(0, 6);
        this.ready = true;
      });

      socket.on('updateRates', info => {
        this.main.bobles.forEach(element => {
          let boble = info.find(x => x.symbol === element.symbol);
          let allBoble = this.all.bobles.find(x => x.symbol == element.symbol);
          element.coinInfo = boble.coinInfo;
          allBoble.coinInfo = boble.coinInfo;
          if (this.test.symbol == element.symbol) this.setModal(element);
        });
      });
    },
    data() {
      return {
        test: {
          name: null,
          symbol: null,
          description: null,
          price: null,
          mc: null,
          volume: null,
          priceChange: null
        },
        ready: false,
        main: {
          name: 'main',
          bobles: [],
          showCount: 6,
          show: false,
          sort: null
        },
        progress: {
          name: 'progress',
          bobles: [],
          showCount: 6,
          show: false,
          sort: null
        },
        all: {
          bobles: []
        },
      }
    },
    methods: {
      showItems(obj) {
        obj.showCount += 6;
        if (!obj.sort) obj.bobles = this.all.bobles.slice(0, obj.showCount);
        else if (obj.sort == 'best') this.sortBest(null, obj);
        else if (obj.sort == 'worst') this.sortWorst(null, obj);
        if (obj.showCount >= this.all.bobles.length) obj.show = true;
      },

      setModal(info) {
        if (info.coinInfo) this.test = info.coinInfo;
        console.log(this.test);
      },

      sortBest(event, obj) {
        if (event) event.preventDefault();
        let info = Array.from(this.all.bobles);
        info.sort(function (a, b) {
          let first = Number((obj.name != 'main') ? a.curPercent : a.percent);
          let second = Number((obj.name != 'main') ? b.curPercent : b.percent);
          first = (first == 0) ? 50 : first;
          second = (second == 0) ? 50 : second;

          if (b.newBoble) return 1;
          else if (first > second) return -1;
          else return 1;
        });
        if (obj.show) obj.bobles = info;
        else obj.bobles = info.slice(0, obj.showCount);

        obj.sort = 'best';
      },

      sortWorst(event, obj) {
        if (event) event.preventDefault();
        let info = Array.from(this.all.bobles);
        info.sort(function (a, b) {
          let first = Number((obj.name != 'main') ? a.curPercent : a.percent);
          let second = Number((obj.name != 'main') ? b.curPercent : b.percent);
          first = (first == 0) ? 50 : first;
          second = (second == 0) ? 50 : second;

          if (b.newBoble) return 1;
          else if (first < second) return -1;
          else return 1;
        });
        if (obj.show) obj.bobles = info;
        else obj.bobles = info.slice(0, obj.showCount);

        obj.sort = 'worst';
      }
    }
  }
</script>

<style>
  html,
  body {
    background-color: #1a1a1a;
    color: #fff;
  }

  h1,
  h2,
  h3,
  p,
  a {
    color: #fff;
  }

  .btn-primary {
    background-color: #00337d;
  }

  .menu-btn {
    padding: 10px;
    color: black !important;
    background-color: #ffc107;
  }

  .menu-push a {
    margin-bottom: 20px;
    text-decoration: none;
    font-weight: 600;
  }

  .bm-burger-button {
    top: 25px !important;
  }

  .bm-burger-bars {
    background-color: #fff !important;
  }

  .bm-item-list {
    font-size: 18px;
  }
</style>